<template>
  <div>
    <div v-if="!signedUp" class="p-0">
      <b-overlay :show="loading">
        <div class="text-center d-flex flex-column" style="gap: 30px;">
          <h1 class="text-24">Create an account</h1>
        </div>
        <b-alert
          variant="danger"
          show
        >
          <div v-if="error" class="alert-body font-small-2">
            <b-card-text>
              <feather-icon icon="AlertCircleIcon" />
              <small>{{ error }}</small>
            </b-card-text>
          </div>
        </b-alert>

        <validation-observer ref="registerForm" #default="{invalid}">
          <b-form @submit.prevent="signup">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Name"
                vid="name"
                rules="required"
              >
                <b-form-input v-model="registration.fullname" size="lg" placeholder="Name"></b-form-input>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="username"
                rules="required"
              >
                <b-form-input v-model="registration.email" size="lg" type="email" placeholder="Email"></b-form-input>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required|max:10"
              >
                <b-form-input v-model="registration.phone" size="lg" placeholder="Phone number"></b-form-input>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="password"
              >
                <b-form-input v-model="registration.password" size="lg" autocomplete="false" :type="passwordFieldType" placeholder="Password"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:password"
              >
                <b-form-input v-model="registration.repeat_password" size="lg" autocomplete="false" :type="passwordFieldType" placeholder="Re-enter password"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div>
              <b-button
                class="py-1"
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign Up
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>

      <h4 class="text-center my-2 text-18-600" style="color: #151314">or</h4>
      <social-auth :show-sign-in-link="true" />
    </div>
    <SignupSuccess v-else @close="closeView" />
  </div>

</template>

<script>
import SocialAuth from "@/@core/components/shared/SocialAuth.vue"
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";

import { get } from "lodash"
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { MUTATE_LOGIN_STATUS, MUTATE_USER_DATA } from "@/store/config/mutation-keys"

import AppLogo from '@core/layouts/components/Logo.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {
  BImg,
  BForm,
  BLink,
  BAlert,
  BButton,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  VBTooltip,
} from 'bootstrap-vue'
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SignupSuccess from "./signup-success.vue";

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    SignupSuccess,
    SocialAuth,
    AuthWrapper,
    BAlert,
    BLink,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BImg,
    BForm,
    BButton,
    // BAlert,
    AppLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      signedUp: false,
      loading: false,
      name: '',
      email: '',
      remember_me: '',
      error: '',
      registration: {
        phone: '',
        password: '',
        repeat_password: '',
        email: '',
        fullname: '',
      },
      // validation rules
      required
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async signup() {
      try {
        this.loading = true;
        this.errorMessage = '';

        const success = await this.$refs.registerForm.validate();
        if (!success) {
          return;
        }

        const formData = new FormData();

        Object.keys(this.registration)
          .forEach(field_name => {
            formData.append(field_name, this.registration[field_name]);
          });

        const response = await this.useJwt().authService.register(formData);
        const loginData = this.getValueFromSource(response, 'data.data')
        this.processLogin(loginData)
        this.signedUp = true
      } catch (error) {
        console.log(error)
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        if (error_message.includes("An account with this email already exists.")) {
          this.$refs.registerForm.setErrors({
            email: [error_message]
          });
        }

        if (error_message.includes("An account with this phone number already exists.")) {
          this.$refs.registerForm.setErrors({
            phone: [error_message]
          });
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    processLogin(loginData) {
      try {
        const { access_token, user } = loginData; 
        this.useJwt().authService.setToken(access_token)
        this.$store.commit(`auth/${MUTATE_LOGIN_STATUS}`, true)
        this.$store.commit(`auth/${MUTATE_USER_DATA}`, user);
  
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Welcome ${user.first_name || user.last_name}`,
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `You have successfully logged in.`,
          },
        })
        this.$store.commit("ristic/RESET_AUTH_UI_STATE");
      } catch (error) {
        console.log(error)
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;
      }
    },
    closeView() {
      this.signedUp = false
      this.$refs.registerForm.reset()
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
@import '@core/scss/vue/pages/page-public.scss';

sup {
  font-size: 1rem;
}

.text-24 {
  color: #151314;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
