<template>
  <div>
    <div v-if="!show_success" class="p-0">
      <b-overlay :show="loading">
        <div class="text-center d-flex flex-column" style="gap: 30px">
          <div>
            <app-logo width="148px" />
          </div>
          <h1 class="text-24 mb-2">Reset Your Password</h1>
        </div>
        <b-alert variant="danger" show>
          <div v-if="error" class="alert-body font-small-2">
            <b-card-text>
              <feather-icon icon="AlertCircleIcon" />
              <small>{{ error }}</small>
            </b-card-text>
          </div>
        </b-alert>

        <validation-observer ref="passwordResetForm" #default="{ invalid }">
          <b-form @submit.prevent="resetPassword">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="password"
              >
                <b-form-input
                  v-model="reset_data.password"
                  size="lg"
                  autocomplete="false"
                  :type="passwordFieldType"
                  placeholder="Password"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:password"
              >
                <b-form-input
                  v-model="reset_data.repeat_password"
                  size="lg"
                  autocomplete="false"
                  :type="passwordFieldType"
                  placeholder="Re-enter password"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div>
              <b-button
                class="py-1"
                type="submit"
                variant="primary"
                block
                :disabled="invalid || disable_further_attempts"
              >
                Reset Password
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>

      <h4 class="text-center my-2 text-18-600" style="color: #151314">or</h4>
      <social-auth :show-sign-in-link="true" />
    </div>
  </div>
</template>

<script>
import SocialAuth from "@/@core/components/shared/SocialAuth.vue";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";

import { get } from "lodash";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import AppLogo from "@core/layouts/components/Logo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BImg,
  BForm,
  BLink,
  BAlert,
  BButton,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  VBTooltip,
} from "bootstrap-vue";
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "AuthResetPassword",
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    SocialAuth,
    AuthWrapper,
    BAlert,
    BLink,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BImg,
    BForm,
    BButton,
    AppLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      show_success: false,
      loading: false,
      name: "",
      email: "",
      remember_me: "",
      error: "",
      disable_further_attempts: false,
      reset_data: {
        password: "",
        repeat_password: "",
      },
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    async resetPassword() {
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.passwordResetForm.validate();
        if (!success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error!",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: "Passwords do not match or are not provided.",
            },
          });
          return;
        }

        await this.useJwt().authService.resetPassword({
          password: this.reset_data.password,
        });

        const password_reset_message =
          "Password reset completed! You can now login with your new password.";
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CoffeeIcon",
            variant: "success",
            text: password_reset_message,
          },
        });
        this.$store.commit("ristic/SET_AUTH_UI_STATE", {
          key: "passwordReset",
          value: false,
        });
        setTimeout(() => {
          this.$store.commit("ristic/SET_AUTH_UI_STATE", {
            key: "login",
            value: true,
          });
        }, 300)

        this.$router.replace({ query: {} }).catch(() => {});
      } catch (error) {
        console.log(error);
        const error_message =
          get(error, "response.data.message") || error.message;
        this.error = error_message;

        if (error_message.includes("Invalid or expired token")) {
          this.error =
            "Your password reset link is invalid or has expired. Please request a new one.";
          this.disable_further_attempts = true;
          setTimeout(() => {
            this.$store.commit("ristic/SET_AUTH_UI_STATE", {
              key: "passwordReset",
              value: false,
            });
            this.$store.commit("ristic/SET_AUTH_UI_STATE", {
              key: "forgotPassword",
              value: true,
            });
          }, 3500);
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    closeView() {
      this.show_success = false;
      this.$refs.passwordResetForm.reset();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
@import "@core/scss/vue/pages/page-public.scss";

sup {
  font-size: 1rem;
}

.text-24 {
  color: #151314;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
