<template>
  <div class="navbar-container">
    <div v-if="getValueFromSource(generalAppSettings, 'system_message_settings.message')">
      <b-alert 
        v-if="getValueFromSource(generalAppSettings, 'system_message_settings.message')"
        class="text-center p-1"
        show
        :variant="getValueFromSource(generalAppSettings, 'system_message_settings.message_variant')"
      >
        {{ getValueFromSource(generalAppSettings, 'system_message_settings.message') }}
      </b-alert>
    </div>

    <div class="navbar-container d-flex content align-items-center">
  
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none p-1 d-flex align-items-start justify-content-between w-100">
        <img src="@/assets/images/logo/logo.png" width="120px" />
        
        <li class="nav-item">
          <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
          >
            <feather-icon
              icon="MenuIcon"
              size="21"
            />
          </b-link>
        </li>
      </ul>
  
  
    </div>
  </div>
</template>

<script>
import {
  BAlert,
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
// import SearchBar from './components/SearchBar.vue'
import CartDropdown from './components/CartDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'

export default {
  components: {
    BLink,
    BAlert,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    DarkToggler,
    // SearchBar,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

}
</script>
