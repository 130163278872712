var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.signedUp)?_c('div',{staticClass:"p-0"},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('div',{staticClass:"text-center d-flex flex-column",staticStyle:{"gap":"30px"}},[_c('h1',{staticClass:"text-24"},[_vm._v("Create an account")])]),_c('b-alert',{attrs:{"variant":"danger","show":""}},[(_vm.error)?_c('div',{staticClass:"alert-body font-small-2"},[_c('b-card-text',[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}}),_c('small',[_vm._v(_vm._s(_vm.error))])],1)],1):_vm._e()]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.signup.apply(null, arguments)}}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"lg","placeholder":"Name"},model:{value:(_vm.registration.fullname),callback:function ($$v) {_vm.$set(_vm.registration, "fullname", $$v)},expression:"registration.fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Email","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"lg","type":"email","placeholder":"Email"},model:{value:(_vm.registration.email),callback:function ($$v) {_vm.$set(_vm.registration, "email", $$v)},expression:"registration.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"phone","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"lg","placeholder":"Phone number"},model:{value:(_vm.registration.phone),callback:function ($$v) {_vm.$set(_vm.registration, "phone", $$v)},expression:"registration.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"lg","autocomplete":"false","type":_vm.passwordFieldType,"placeholder":"Password"},model:{value:(_vm.registration.password),callback:function ($$v) {_vm.$set(_vm.registration, "password", $$v)},expression:"registration.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"lg","autocomplete":"false","type":_vm.passwordFieldType,"placeholder":"Re-enter password"},model:{value:(_vm.registration.repeat_password),callback:function ($$v) {_vm.$set(_vm.registration, "repeat_password", $$v)},expression:"registration.repeat_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('b-button',{staticClass:"py-1",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" Sign Up ")])],1)],1)]}}],null,false,2853752714)})],1),_c('h4',{staticClass:"text-center my-2 text-18-600",staticStyle:{"color":"#151314"}},[_vm._v("or")]),_c('social-auth',{attrs:{"show-sign-in-link":true}})],1):_c('SignupSuccess',{on:{"close":_vm.closeView}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }