<template>
  <div class="custom-sidebar">
    <b-sidebar
      v-model="show"
      width="350px"
      bg-variant="white"
      size="md"
      backdrop-variant="dark"
      backdrop
      title="Car Booking"
      no-close-on-backdrop
      no-close-on-esc
      right
      shadow
    >
      <template #header>
        <div
          class="d-flex align-items-center justify-content-between w-100"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="cursor-pointer"
            @click="handleClose"
          />
          <h4
            style="font-weight: 700; color: black"
            class="text-black m-0 p-0"
          >
            Car Booking
          </h4>
          <feather-icon
            icon="MoreHorizontalIcon"
            size="18"
            class="cursor-pointer"
          />
        </div>
      </template>

      <div class="sidebar-content px-2">
        <div class="">
          <div>
            <div class="d-flex flex-column justify-content-between">
              <BookingCarDisplayCard :car="carFromStore" />
            </div>

            <div class="mt-2">
              <h6 class="text-18 mb-2">
                Calendar Guide
              </h6>
              <div class="d-flex align-items-center mb-1">
                <div class="start-div"></div><div class="ml-2">Start / End Day</div>
              </div>
              <div class="d-flex align-items-center mb-1">
                <div class="range-div"></div><div class="ml-2">Day in Start / End Range</div>
              </div>
              <div class="d-flex align-items-center">
                <div class="reserved-div"></div><div class="ml-2">Reserved / Unavailable Day</div>
              </div>
            </div>

            <div class="booking-form w-100">
              <b-form-group
                label="Select Day/Period of rental"
                label-for="rental-duration"
              >
                <DateRangeSelector 
                  v-model="booking.rentalDateDuration" 
                  :change-date="resetDateDuration"
                  :reserved-dates="disabled_dates"
                />
              </b-form-group>
              
              <div v-if="rentalDays > 0">
              <b-form-group
                label="Select Pickup/Return time"
                label-for="rental-time"
              >
                <TimeRangeSelector 
                  v-model="booking.rentalTimeDuration" 
                  :change-time="resetTimeDuration"
                />
              </b-form-group>

              <b-form-group
                label="Pickup point"
                label-for="Pickup"
              >
                <v-select
                  id="Pickup"
                  v-model="booking.pickupLocation"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pickupLocations"
                  :reduce="(val) => val.value"
                  label="label"
                  :clearable="false"
                />
              </b-form-group>

              <b-form-group label="Airport Drop off" label-for="airport-drop-off">
                <b-form-checkbox 
                  v-model="booking.airportDropOff"
                  class="text-dark d-flex align-items-center"
                >
                  <span class="text-dark">
                    Drop me off at the airport
                  </span>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group label="Rental Location(s)" label-for="rental locations">
                <small class="text-warning">
                  This car is available for rent in {{ defaultLocationName }}.
                </small>

                <b-form-checkbox 
                  v-if="nonDefaultLocationsPrices.length"
                  v-model="booking.useOutsideDefaultLocation"
                  class="text-dark d-flex align-items-center mt-2"
                >
                  <span class="text-dark">
                    Would you use this car in other places?
                  </span>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group v-if="booking.useOutsideDefaultLocation" label="Available destinations" label-for="destination" style="max-height: 200px; overflow-y: auto;">
                <b-form-checkbox-group
                  v-model="booking.destinations"
                  name="car-types"
                  class="w-full demo-inline-spacing"
                >
                  <small class="text-warning">Each extra area or destination comes with its cost.</small>
                  <b-row>
                    <b-col v-for="(carPrice, i) in nonDefaultLocationsPrices" :key="i" cols="6" md="12" class="w-full mb-1">
                      <div class="w-full d-flex justify-content-between align-items-center">
                        <b-form-checkbox 
                          :value="carPrice._id" 
                          class="text-dark"
                        >
                          <span class="text-dark">
                            {{ carPrice.location }} <small class="text-muted">${{ carPrice.rate }}/day</small>
                          </span>
                        </b-form-checkbox>
                        <div v-if="booking.destinations.includes(carPrice._id)" class="d-flex align-items-center">
                          <feather-icon icon="MinusIcon" size="18" class="cursor-pointer operator-btn" @click="updateDaysAtLocation(carPrice._id, -1)" />
                          <span>{{ booking.daysAtLocation[carPrice._id] || 0 }}</span> <small class="text-muted" style="margin-left: 5px;">days</small>
                          <feather-icon icon="PlusIcon" size="18" class="cursor-pointer operator-btn" @click="updateDaysAtLocation(carPrice._id, 1)" />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-form-checkbox-group>
              </b-form-group>

              <b-form-group
                label="Payment Option"
                label-for="payment_option"
              >
                <v-select
                  id="payment_option"
                  v-model="booking.paymentOption"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentOptions"
                  :reduce="(val) => val.value"
                  label="label"
                  :clearable="false"
                />
              </b-form-group>
              </div>

            </div>
          </div>
          <div
            v-if="rentalDays > 0"
            class="mb-3 pb-2 mt-2"
          >
            <div>
              <h6 class="text-18 mb-2">
                Cost breakdown
              </h6>

              <div class="d-flex align-items-center justify-content-between mb-0.5">
                <b-card-text class="p-0 m-0">
                  Rental days
                </b-card-text>

                <b-card-text class="p-0 m-0">
                  {{ rentalDays }}
                </b-card-text>
              </div>

              <div class="d-flex align-items-center justify-content-between mb-0.5">
                <b-card-text class="p-0 m-0">
                  Rental destinations
                </b-card-text>

                <b-card-text class="p-0 m-0">
                  {{ booking.destinations.length + 1 }}
                </b-card-text>
              </div>

              <div v-if="hasSelectedNonDefaultLocation" class="d-flex align-items-center justify-content-between mb-0.5">
                <b-card-text class="p-0 m-0">
                  Driver accommodation
                  <small class="text-muted">(x {{ nightsDuringRental }} nights)</small>
                </b-card-text>

                <b-card-text class="p-0 m-0">
                  ${{ formatMoney(totalDriverAccommodationCost) }}
                </b-card-text>
              </div>

              <div v-if="booking.pickupLocation === 'airport'" class="d-flex align-items-center justify-content-between mb-0.5">
                <b-card-text class="p-0 m-0">
                  Airport Pickup
                </b-card-text>

                <b-card-text class="p-0 m-0">
                  ${{ formatMoney(pickupRate) }}
                </b-card-text>
              </div>

              <div v-if="booking.airportDropOff" class="d-flex align-items-center justify-content-between mb-0.5">
                <b-card-text class="p-0 m-0">
                  Airport Drop Off
                </b-card-text>

                <b-card-text class="p-0 m-0">
                  ${{ formatMoney(dropOffRate) }}
                </b-card-text>
              </div>

              <div v-if="hoursAfterHours" class="d-flex align-items-center justify-content-between mb-0.5">
                <b-card-text class="p-0 m-0">
                  After Hours Cost <span class="text-muted">(x {{ hoursAfterHours }} hours)</span>
                </b-card-text>

                <b-card-text class="p-0 m-0">
                  ${{ formatMoney(afterHoursCost) }}
                </b-card-text>
              </div>

              <div class="d-flex align-items-center justify-content-between mb-0.5">
                <b-card-text class="p-0 m-0">
                  Car rental <span class="text-muted">(x {{ rentalDays }} days)</span>
                </b-card-text>

                <b-card-text class="p-0 m-0">
                 ${{ formatMoney(totalBookingBasePrice) }}
                </b-card-text>
              </div>

              <div v-if="locationAddons" class="d-flex align-items-center justify-content-between mb-0.5">
                <b-card-text class="p-0 m-0">
                  Extra Location Charges
                  <feather-icon v-b-tooltip.hover v-b-tooltip.hover.html="locationsTooltip" icon="HelpCircleIcon">
                  </feather-icon>
                </b-card-text>

                <b-card-text class="p-0 m-0">
                 ${{ formatMoney(locationAddons) }}
                </b-card-text>
              </div>

              <div class="d-flex align-items-center justify-content-between mb-0.5">
                <b-card-text class="p-0 m-0 font-weight-bolder" style="font-size: 1.10rem;">
                  Total
                </b-card-text>

                <b-card-text class="p-0 m-0 font-weight-bolder" style="font-size: 1.10rem;">
                 ${{ formatMoney(totalBookingAmount) }}
                </b-card-text>
              </div>

              <div class="d-flex align-items-center justify-content-between mb-0.5">
                <b-card-text class="p-0 m-0 font-weight-bolder" style="font-size: 1.15rem;">
                  Amount to pay now
                </b-card-text>

                <b-card-text class="p-0 m-0 font-weight-bolder" style="font-size: 1.15rem;">
                 ${{ formatMoney(amountToPayNow) }}
                </b-card-text>
              </div>


            </div>

            <b-button class="mt-2 btn" :disabled="!isValidBooking || loading" size="lg" variant="primary" @click="bookCar">Book Car</b-button>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>  
</template>

<script>
import { differenceInCalendarDays, isValid } from "date-fns";
import {
  BImg,
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BCardText,
  BOverlay,
  BSidebar,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  VBTooltip,
  BInputGroupText,
  BInputGroupAddon,
  BFormCheckboxGroup,
} from "bootstrap-vue";

import { round } from "lodash";
import CarDisplayCard from "@/@core/components/shared/CarDisplayCard.vue";
import DateRangeSelector from "@/@core/components/shared/DateRangeSelector.vue";
import TimeRangeSelector from "@/@core/components/shared/TimeRangeSelector.vue";
import CustomScrollWrapper from "@/@core/components/shared/CustomScrollWrapper.vue";
import BookingCarDisplayCard from "@/@core/components/shared/BookingCarDisplayCard.vue";

import vSelect from "vue-select";
import CarsMixin from "@/@core/mixins/cars";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BOverlay,
    BSidebar,
    BFormInput,
    BInputGroupText,
    BInputGroupAddon,
    BFormGroup,
    BAvatar,
    BInputGroup,

    vSelect,
    CarDisplayCard,
    DateRangeSelector,
    BFormCheckbox,
    TimeRangeSelector,
    BFormCheckboxGroup,
    CustomScrollWrapper,
    BookingCarDisplayCard,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [CarsMixin],
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      booking: {
        driverAccommodation: '',
        destinations: [],
        airportPickup: false,
        airportDropOff: false,
        rentalTimeDuration: [this.openingTime, this.closingTime],
        pickupLocation: 'airport',
        paymentOption: 'half_payment',
        rentalDateDuration: [new Date(), new Date()],
        daysAtLocation: {},
        useOutsideDefaultLocation: false,
      },
      disabled_dates: [],
      resetDateDuration: [new Date(), new Date()],
      resetTimeDuration: ["12:00", "12:00"],
      pickupLocations: [
        {
          label: 'Kotoka International Airport',
          value: 'airport'
        },
        {
          label: 'Arrange With Driver',
          value: 'arrange_with_drive'
        }
      ],
      paymentOptions: [
        {
          label: 'Pay half now and the rest later',
          value: 'half_payment'
        },
        {
          label: 'Pay full to reserve now',
          value: 'full_payment'
        }
      ],
      showRentSidebar: false,
      loading: false,
    };
  },
  computed: {
    defaultLocationName() {
      const defaultLocationPrice = this.carPricingListForAttachedLocations.find(carPricing => carPricing.is_default_location);
      return defaultLocationPrice ? defaultLocationPrice.location : '';
    },
    hasFreeDays() {
      const totalDays = Object.values(this.booking.daysAtLocation).reduce((acc, curr) => acc + curr, 0);
      return this.rentalDays > totalDays
    },
    hasSelectedNonDefaultLocation() {
      const { destinations = [] } = this.booking;
      const selectedPrices = this.carPricingListForAttachedLocations.filter((carPricing) => destinations.includes(carPricing._id));
      return selectedPrices.some(carPrice => !carPrice.is_default_location)
    },
    driverAccommodationOptions() {
      return [
        {
          label: "I'll provide accommodation",
          value: 'client'
        },
        {
          label: `Ristic Rental $${this.formatMoney(this.driverAccommodationPerNight)}/night`,
          value: 'ristic',
        }
      ]
    },
    driverAccommodationPerNight() {
      const { driver_accommodation_rate = 0 } = this.generalAppSettings.car_rental_settings;
      return driver_accommodation_rate;
    },
    totalDriverAccommodationCost() {
      return this.driverAccommodationPerNight * (this.nightsDuringRental);
    },
    hoursAfterHours() {
      if (!Array.isArray(this.booking.rentalDateDuration)) return 0;
      if (!Array.isArray(this.booking.rentalTimeDuration)) return 0;

      const [, endDate] = this.booking.rentalDateDuration;
      const [, endTime] = this.booking.rentalTimeDuration;

      if (!endDate || !endTime) return 0;
      const hour_after_hours = this.computeAfterHours(endTime);
      return hour_after_hours;
    },
    carFromStore() {
      return this.makeCarDisplayData(this.$store.state.ristic.currentCar);
    },
    dropOffRate() {
      return this.getValueFromSource(this.carFromStore, "drop_off_rate");
    },
    pickupRate() {
      return this.getValueFromSource(this.carFromStore, "pickup_rate");
    },
    afterHoursRate() {
      return this.getValueFromSource(this.carFromStore, "after_hours_rate");
    },
    afterHoursCost() {
      return this.afterHoursRate * this.hoursAfterHours;
    },
    carImages() {
      if (this.carFromStore) {
        return [
          ...(this.carFromStore.car_images || []).map((carImage) => ({
            img: this.getValueFromSource(carImage, "image.path"),
          })),
        ];
      }
      return [];
    },
    carPricingListForAttachedLocations() {
      if (this.carFromStore && this.carFromStore.car_pricing) {
        return this.carFromStore.car_pricing.map(opt => ({
          _id: opt._id,
          rate: opt.amount,
          location: this.getValueFromSource(opt, 'location.title'),
          is_default_location: this.getValueFromSource(opt, 'location.is_default_location'),
        })) || []
      }

      return [];
    },
    nonDefaultLocationsPrices() {
      return this.carPricingListForAttachedLocations.filter((d) => !d.is_default_location)
    },
    bookingLocations() {
      return this.$store.state.ristic.bookingLocations;
    },
    hasValidBookingDates() {
      const { rentalDateDuration } = this.booking;
      if (!rentalDateDuration) return false;

      const lengthCriteria = rentalDateDuration.length === 2;
      const allDatesValid = rentalDateDuration.every((dateString) => dateString && isValid(new Date(dateString)));

      return [lengthCriteria, allDatesValid].every((criteria) => criteria);
    },
    hasValidBookingPickupTimes() {
      const { rentalTimeDuration } = this.booking;
      if (!rentalTimeDuration) return false;

      const [pickupTime, ] = rentalTimeDuration;
      return !!pickupTime;
    },
    isValidBooking() {
      return (
        this.booking.pickupLocation &&
        this.hasValidBookingPickupTimes &&
        this.hasValidBookingDates
      );
    },
    rentalDays() {
      if (this.hasValidBookingDates) {
        const [startDate, endDate] = this.booking.rentalDateDuration;
        let fullDays = differenceInCalendarDays(new Date(endDate), new Date(startDate));

        const [, endTime] = this.booking.rentalTimeDuration;
        if (endTime) {
          const [endHour] = endTime.split(':');
          const parsedEndHour = parseInt(endHour, 10);
          if (parsedEndHour > this.openingHour) {
            fullDays += 1;
          }
        }
        return fullDays
      }
      return 0
    },
    bookingDestinationsPrices() {
      return (this.carFromStore.car_pricing || []).filter(((car_pricing) => this.booking.destinations.includes(car_pricing._id)));
    },
    locationAddons() {
      let total = 0;
      this.bookingDestinationsPrices.forEach((location) => {
        const days = this.booking.daysAtLocation[location._id] || 0;
        total += location.amount * days;
      });
      return total
    },
    baseRatePerDay() {
      const defaultPrice = this.carPricingListForAttachedLocations.find(d => d.is_default_location);
      return defaultPrice ? defaultPrice.rate : 0;
    },
    total() {
      const defaultPrice = this.carPricingListForAttachedLocations.find(d => d.is_default_location);
      return defaultPrice ? defaultPrice.rate : 0;
    },
    totalBookingAmountPerDay() {
      if (!this.bookingDestinationsPrices || !this.bookingDestinationsPrices.length) { return this.carFromStore.rate }
      
      return this.bookingDestinationsPrices.reduce((total, location) => {
        return total + (location.amount || 0);
      }, 0);
    },
    totalBookingBasePrice() {
      return this.rentalDays * this.baseRatePerDay;
    },
    totalBookingAmount() {
      let totalAmount = (this.rentalDays * this.baseRatePerDay) + this.locationAddons;
      
      if (this.hoursAfterHours) {
        totalAmount += this.afterHoursCost;
      }

      if (this.hasSelectedNonDefaultLocation) {
        totalAmount += this.totalDriverAccommodationCost;
      }

      if (this.booking.airportDropOff) {
        totalAmount += this.dropOffRate;
      }

      if (this.booking.pickupLocation === 'airport') {
        totalAmount += this.pickupRate;
      }

      return totalAmount
    },
    amountToPayNow() {
      const { paymentOption } = this.booking
      return paymentOption === 'half_payment' ? (this.totalBookingAmount / 2) : this.totalBookingAmount
    },
    defaultBookingLocation() {
      return this.bookingLocations.find((location) => location.is_default_location);
    },
    defaultLocationPriceId() {
      const default_price = (this.carFromStore.car_pricing || []).filter(((car_pricing) => car_pricing.location.is_default_location));
      return default_price.length ? default_price[0]._id : "";
    },
    nightsDuringRental() {
      let nights = 0;

      if (!this.booking.rentalDateDuration || !Array.isArray(this.booking.rentalDateDuration)) return 0;
      if (!this.booking.rentalTimeDuration || !Array.isArray(this.booking.rentalTimeDuration)) return 0;

      const [startDateString, endDateString] = this.booking.rentalDateDuration;

      if (!startDateString || !endDateString) return 0;

      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);

      const [startTime, endTime] = this.booking.rentalTimeDuration;
      const [startHour, startMinute] = startTime.split(":").map(Number);
      const [endHour, endMinute] = endTime.split(":").map(Number);

      startDate.setHours(startHour, startMinute, 0, 0);
      endDate.setHours(endHour, endMinute, 0, 0);

      nights = differenceInCalendarDays(endDate, startDate);

      return Math.max(0, nights)
    }
  },
  watch: {
    "booking.useOutsideDefaultLocation": {
      handler(val) {
        if (!val) {
          this.booking.daysAtLocation = {};
        }
      }
    },
    show: {
      handler() {
        if (this.isLoggedIn) {
          this.fetchReservedDates()
        }
      },
      immediate: true,
    },
    "booking.destinations": {
      handler(val) {
        val.forEach((location) => {
          if (!this.booking.daysAtLocation[location]) {
            this.booking.daysAtLocation[location] = 1; 
          }
        });
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    async bookCar() {
      try {
        this.loading = true;

        const payload = {
          source_location_ids: this.booking.destinations,

          booking_end_date: this.booking.rentalDateDuration[1],
          booking_start_date: this.booking.rentalDateDuration[0],

          booking_end_time: this.booking.rentalTimeDuration[1],
          booking_start_time: this.booking.rentalTimeDuration[0],

          car_id: this.carFromStore.id,
          pickup_location: this.booking.pickupLocation,
          payment_option: this.booking.paymentOption,
          airport_drop_off: this.booking.airportDropOff,

          days_at_location: this.booking.daysAtLocation,
        };
        
        const response = await this.useJwt().ristic.car_bookings.book(payload);
        const response_data = this.getValueFromSource(response, 'data.data')

        const { url } = response_data;
        location.href = url;
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchReservedDates() {
      try {
        this.loading = true;

        if (this.$route.params.id) {
          const request = await this.useJwt().ristic.car_bookings.getReservedDates(this.$route.params.id);
          const { data } = request.data;
          
          this.disabled_dates = data
        } else {
          return
        }
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    locationsTooltip() {
      let finalHtml = ''
      this.bookingDestinationsPrices.forEach((p) => {
        finalHtml += `<p class='m-0 p-0'><strong>${p.location.title}</strong> - $${this.formatMoney(p.amount)} (${this.booking.daysAtLocation[p._id]} days)</p>`
      })
      return finalHtml
    },
    updateDaysAtLocation(location, numberOfDays) {
      let locationDays = this.booking.daysAtLocation[location] || 0;
      locationDays += numberOfDays;
      if (locationDays <= 0) {
        return;
      }

      const newDaysAtLocation = { ...this.booking.daysAtLocation, [location]: locationDays };
      const total = Object.values(newDaysAtLocation).reduce((accumulation, days) => accumulation + days, 0);
      if (total > this.rentalDays) { return }
      this.booking.daysAtLocation = newDaysAtLocation
    },
    handleClose() {
      this.booking = {
        pickupLocation: 'airport',
        paymentOption: 'half_payment',
        driverAccommodation: '',
        destinations: [],
        airportPickup: false,
        airportDropOff: false,
        rentalTimeDuration: [this.openingTime, this.closingTime],
        rentalDateDuration: [new Date(), new Date()],
        daysAtLocation: {},
        useOutsideDefaultLocation: false,
      };
      this.disabled_dates = [];
      this.resetDateDuration = [new Date(), new Date()];
      this.resetTimeDuration = ["12:00", "12:00"];
      this.$store.commit("ristic/RESET_RENTAL_AUTH_UI_STATE");
      this.$store.commit("ristic/RESET_BOOKING_LOCATIONS");
      this.$emit('update:show', false)
    },
    isBefore(time1, time2) {
      const [time1Hours, time1Minutes] = time1.split(":");
      const [time2Hours, time2Minutes] = time2.split(":");
      return (
        parseInt(time1Hours, 10) < parseInt(time2Hours, 10) ||
        (parseInt(time1Hours, 10) === parseInt(time2Hours, 10) &&
          parseInt(time1Minutes, 10) < parseInt(time2Minutes, 10))
      );
    },
    isValidTime(time) {
      return (
        typeof time.hours === "number" &&
        typeof time.minutes === "number" &&
        !isNaN(time.hours) &&
        !isNaN(time.minutes) &&
        time.hours >= 0 &&
        time.hours < 24 &&
        time.minutes >= 0 &&
        time.minutes < 60
      );
    },
    parseTime(timeStr) {
      const [hours, minutes] = timeStr.split(":").map(Number);
      return hours * 60 + minutes;
    },
    computeAfterHours(bookingEndTimeStr) {
      if (!bookingEndTimeStr) return 0;
      if (!this.generalAppSettings.car_rental_settings || !this.generalAppSettings._id) return 0

      const { opening_time, closing_time } = this.generalAppSettings.car_rental_settings
      if (!opening_time || !closing_time) return 0

      const startTime = this.parseTime(opening_time);
      const endTime = this.parseTime(closing_time);
      const bookingEndTime = this.parseTime(bookingEndTimeStr);

      const minutesInDay = 24 * 60;

      let afterHours = 0;

      if (bookingEndTime > endTime) {
        afterHours += bookingEndTime - endTime;
      }

      if (bookingEndTime < startTime) {
        afterHours += bookingEndTime;
        afterHours += minutesInDay - endTime;
      }

      return round(afterHours / 60, 2);
    }
  },
};
</script>

<style lang="scss">
.operator-btn {
  border: 1px solid #f8d7da;
  border-radius: 99px;
  margin-left: 5px;
  margin-right: 5px;
}

.operator-btn:hover {
  opacity: 0.8;
  background-color: #f8d7db;
}

.custom-sidebar .b-sidebar {
  background-color: white;
}

.booking-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
}

.booking-form .form-group > label {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.2px;
  color: #151314;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.form-description {
  font-family: "Nunito Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  color: #898384;
}

.flatpickr-calendar {
  opacity: 0;
  display: none;
  visibility: hidden;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.pricing-model {
  color: #58B0E0;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 400;
  display: block;
}

.sidebar-content {
  margin-left: 60px;
}

.start-div {
  background-color: #a1cb3a;
  width: 30px;
  height: 30px;
  border-radius: 8px;
}

.reserved-div {
  background-color: #f8d7da;
  width: 30px;
  height: 30px;
  border-radius: 8px;
}

.range-div {
  background-color: #e0eebd;
  width: 30px;
  height: 30px;
  border-radius: 8px;
}

@media screen and (min-width: 308px) {
  .sidebar-content {
    margin-left: 0;
  }
}
</style>
