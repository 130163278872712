<!-- eslint-disable guard-for-in -->
<template>
  <div>
    <div class="d-flex flex-column" style="gap: 10px">
      <button class="social-auth-container" @click="continueWithApple">
        <Apple />
        <span class="social-auth-title">Continue with Apple</span>
      </button>
      <button class="social-auth-container" @click="continueWithGoogle">
        <Google />
        <span class="social-auth-title">Continue with Google</span>
      </button>
    </div>
    <div v-if="showSignUpLink" class="text-center">
      <p class="pt-2">
        Don't have an account?
        <span>
          <b-link
            style="color: #1e6bff; cursor: pointer"
            @click="toggleUIState()"
          >
            <span>Sign Up</span>
          </b-link>
        </span>
      </p>
    </div>
    <div v-if="showSignInLink" class="text-center">
      <p class="pt-2">
        Already have an account?
        <span>
          <b-link
            style="color: #1e6bff; cursor: pointer"
            @click="toggleUIState('login')"
          >
            <span>Log in</span>
          </b-link>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";

import Apple from "@/assets/svg/apple_auth.svg";
import Google from "@/assets/svg/google_auth.svg";
import Facebook from "@/assets/svg/facebook_symbol.svg";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "SocialAuth",
  components: {
    Facebook,
    Google,
    Apple,
    BLink,
  },
  props: {
    showSignUpLink: {
      type: Boolean,
      default: false,
    },
    showSignInLink: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    window.AppleID.auth.init({
      clientId: 'com.risticholdings.risticcars',
      scope: 'name email',
      redirectURI: "https://risticcars.com/oauth/apple",
      response_mode: 'query',
      state: 'Ristic&Apple',
      usePopup: true
    });

    document.addEventListener('AppleIDSignInOnSuccess', (event) => {
      console.log("[AppleIDSignInOnSuccess]", event)
    });

    document.addEventListener('AppleIDSignInOnFailure', (event) => {
      console.log("[AppleIDSignInOnFailure]", event)
      this.showError("Apple login failed. Try again later");
    });
  },
  methods: {
    toggleUIState(key = "register") {
      return this.$store.commit("ristic/SET_AUTH_UI_STATE", {
        key,
        value: true,
      });
    },
    async continueWithApple() {
      try {
        sessionStorage.setItem("oauthLastPage", window.location.href);
        const response = await window.AppleID.auth.signIn()
        console.log("APPLE LOGIN RESPONSE", response);
        if (response.error) {
          throw new Error(response.error);
        }
        this.loginWithApple(response)
      } catch (error) {
        console.log("APPLE LOGIN ERROR", error);
        this.showError("Apple login failed. Try again later");
      }
    },
    continueWithGoogle() {
      const clientId = process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID;
      const redirectUri = process.env.VUE_APP_GOOGLE_OAUTH_REDIRECT_URL;

      if (!clientId || !redirectUri) {
        console.error("Missing required parameters for Google OAuth");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Google OAuth Configuration Error",
            icon: "InfoIcon",
            variant: "warning",
            text: "Google login is currently not available, kindly try again later",
          },
        });
        return;
      }

      const url = new URL("https://accounts.google.com/o/oauth2/v2/auth");
      url.searchParams.set("client_id", clientId);
      url.searchParams.set("redirect_uri", redirectUri);
      url.searchParams.set("response_type", "id_token");
      url.searchParams.set("scope", "openid profile email");
      url.searchParams.set("state", this.generateState());
      url.searchParams.set("nonce", this.generateGoogleAuthNonce());

      sessionStorage.setItem("oauthLastPage", window.location.href);

      window.location.href = url.toString();
    }
  },
};
</script>

<style>
.social-auth-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  gap: 10px;
  padding: 14px 20px;
  background-color: white;
  /* margin-top: 1rem; */
}

.social-auth-container:hover {
  cursor: pointer;
  opacity: 0.8;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.social-auth-title {
  font-family: "Nunito Sans";
  color: #898384;
  font-weight: 600;
  /* font-size: 14px; */
  font-size: 16px;
  line-height: 25.6px;
}

button.social-auth-container:disabled {
  cursor: not-allowed;
  opacity: 0.6;
  box-shadow: none;
}

</style>
