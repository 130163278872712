<template>
  <div id="custom-pickr">
    <vue-flat-pickr
      v-model="date"
      :config="config"
      placeholder="Select Day/Period of rental"
      name="duration"
      class="form-control"
    />

    <div class="root-pickr mt-1">
      <div class="d-flex flex-column duration from">
        <label>From</label>
        <span>{{ fromDate }}</span>
      </div>

      <div style="height: 30px; background-color: #d9d9d9; width: 2px"></div>

      <div class="d-flex flex-column duration from">
        <div class="d-flex justify-content-end">
          <label>To</label>
        </div>
        <span>{{ toDate }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import VueFlatpickr from "vue-flatpickr-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "flatpickr/dist/flatpickr.css";

import { format } from "date-fns";

export default {
  name: "DateRangeSelector",
  components: {
    "vue-flat-pickr": VueFlatpickr,
  },
  props: {
    changeDate: {
      type: Array,
      default: () => []
    },
    reservedDates: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      date: null,
      config: {
        altFormat: "J M, Y",
        altInput: true,
        inline: true,
        disable: [],
        mode: "range",
        disableYearInput: true,
        minDate: format(new Date(), 'yyyy-MM-dd'),
      },
    };
  },
  computed: {
    fromDate() {
      if (this.date) {
        const startDate = this.date.split(" to ")[0];
        return startDate ? this.formatDatePickrDate(startDate) : '';
      }
      return " - ";
    },
    toDate() {
      if (this.date) {
        const endDate = this.date.split(" to ")[1];
        return endDate ? this.formatDatePickrDate(endDate) : '';
      }
      return " - ";
    },
  },
  watch: {
    date() {
      if (this.date){
        const components = this.date.split(" to ")
        if (components.length === 2) {
          const startDate = components[0];
          const endDate = components[1];
          this.$emit("input", [startDate, endDate]);
        }
      }
    },
    changeDate: {
      handler(newVal){
        if (newVal && newVal.length === 2) {
          this.date = null;
        }        
      },
      immediate: true,
    },
    reservedDates: {
      handler(newVal){
        if (newVal && newVal.length > 0) {
          this.config.disable = newVal
        } else {
          this.config.disable = []
        }       
      },
      immediate: true,
    }
  },
  methods: {
    formatDatePickrDate(dateString) {
      return this.formatDate(new Date(dateString), "do MMM, yyyy");
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style>
span.flatpickr-day.selected.startRange,
span.flatpickr-day.selected.endRange,
span.flatpickr-day.startRange,
span.flatpickr-day.endRange,
span.flatpickr-day.selected {
  border-radius: 4px;
  background: var(--green-secondary, #a1cb3a);
  color: #fff;
}
span.flatpickr-day.inRange {
  border-radius: 4px;
  background: var(--Green-Green200, #e0eebd);
}

.numInputWrapper {
  display: none !important;
}

.flatpickr-calendar.rangeMode.animate.inline {
  background-color: #f0f0f0;
  margin-top: 10px;
}

.flatpickr-days .flatpickr-day {
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
  /* line-height: 16.8px; */
}

.flatpickr-day.today {
  border-radius: 4px;
}

[dir] .flatpickr-day.selected,
[dir] .flatpickr-day.startRange,
[dir] .flatpickr-day.endRange,
[dir] .flatpickr-day.selected.inRange,
[dir] .flatpickr-day.startRange.inRange,
[dir] .flatpickr-day.endRange.inRange,
[dir] .flatpickr-day.selected:focus,
[dir] .flatpickr-day.startRange:focus,
[dir] .flatpickr-day.endRange:focus,
[dir] .flatpickr-day.selected:hover,
[dir] .flatpickr-day.startRange:hover,
[dir] .flatpickr-day.endRange:hover,
[dir] .flatpickr-day.selected.prevMonthDay,
[dir] .flatpickr-day.startRange.prevMonthDay,
[dir] .flatpickr-day.endRange.prevMonthDay,
[dir] .flatpickr-day.selected.nextMonthDay,
[dir] .flatpickr-day.startRange.nextMonthDay,
[dir] .flatpickr-day.endRange.nextMonthDay {
  background: var(--green-secondary, #a1cb3a);
  border-color: var(--green-secondary, #a1cb3a);
  border-radius: 4px;
}

#custom-pickr > input.form-control.flatpickr-input {
  display: none !important;
}

#custom-pickr > input.form-control.input {
  display: none !important;
}
</style>
<style>
.root-pickr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 5px;
}

.flatpickr-day.flatpickr-disabled {
  background-color: #f8d7da; 
  color: #ffffff;        
  cursor: not-allowed;
}
</style>
