<template>
  <b-sidebar
    :visible="visible"
    width="350px"
    bg-variant="white"
    size="md"
    backdrop-variant="dark"
    backdrop
    no-close-on-backdrop
    no-close-on-esc
    right
    shadow
  >
    <template #header>
      <div class="d-flex align-items-center w-100 p-1" style="gap: 80px;">
        <feather-icon
          icon="XIcon"
          size="20"
          class="cursor-pointer ml-1"
          @click="closeAuthMode"
        />
        <div>
          <app-logo v-if="showLogo" width="148px" />
          <h4
            v-if="title"
            style="font-weight: 700; color: black"
            class="text-black m-0 p-0"
          >
            {{ title }}
          </h4>
        </div>
        <div></div>
      </div>
    </template>

    <div class="px-2">
      <slot></slot>
    </div>
  </b-sidebar>
</template>

<script>
import { BSidebar } from "bootstrap-vue";
import AppLogo from '@core/layouts/components/Logo.vue';

export default {
  name: "AppSideBar",
  components: { BSidebar, AppLogo },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    closeAuthMode() {
      this.$store.commit("ristic/RESET_AUTH_UI_STATE");
      this.$emit("change", false);
    },
  }
};
</script>

<style lang="scss"></style>
