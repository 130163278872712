<template>
  <div>
    <div id="custom-pickr">
      <div class="root-pickr">
        <div class="d-flex flex-column duration from">
          <label>Pickup Time</label>
          <b-form-timepicker v-model="pickupTime" locale="en"></b-form-timepicker>
        </div>
        <div style="height: 30px; background-color: #d9d9d9; width: 2px;"></div>
        <div class="d-flex flex-column duration from">
          <div class="d-flex justify-content-end">
            <label>Return Time</label>
          </div>
          <b-form-timepicker v-model="returnTime" locale="en"></b-form-timepicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormTimepicker } from "bootstrap-vue"

export default {
  name: "TimeRangeSelector",
  components: {
    BFormTimepicker
  },
  props: {
    changeTime: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      date: null,
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
      },
      timePicker: {
        wrap: true,
        enableTime: true,
        enableSeconds: true,
        noCalendar: true
      },
      pickupTime: this.openingTime,
      returnTime: this.closingTime,
    };
  },
  watch: {
    pickupTime() {
      this.$emit("input", [this.pickupTime, this.returnTime]);
    },
    returnTime() {
      this.$emit("input", [this.pickupTime, this.returnTime]);
    },
    changeTime: {
      handler(newVal){
        if (newVal && newVal.length === 2) {
          this.pickupTime = this.openingTime;
          this.returnTime = this.closingTime;
        }        
      },
      immediate: true,
    }
  },
};
</script>

<style lang="scss" scoped></style>
<style>
.root-pickr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 5px;
}
</style>
