<template>
  <b-modal
    id="add-or-update-modal"
    :visible="isAddOrUpdateActive"
    centered
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    size="md"
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:is-add-or-update-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            Report a problem
          </h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>

        <validation-observer
          #default="{ handleSubmit, invalid }"
          ref="update_modal_form"
        >
          <b-form class="my-1" @submit.prevent="handleSubmit(onCreateOrUpdate)" @reset.prevent="resetForm">
            <b-alert v-if="errorMessage" variant="danger" :show="true">
              <div class="alert-body font-small-2">
                <b-card-text class="mb-0">
                  {{ errorMessage }}
                </b-card-text>
              </div>
            </b-alert>

            <b-row class="mt-2">
              <b-col cols="12" md="12">
                <validation-provider #default="validationContext" name="Title" rules="required">
                  <b-form-group label-for="problem_title">
                    <template #label>
                      <span>Title<span style="color: tomato">*</span></span>
                    </template>

                    <b-form-input
                      id="problem_title"
                      v-model="new_data.problem_title"
                      placeholder="Enter the problem title"
                      :disabled="disableForm"
                      :state="getValidationState(validationContext)"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="12">
                <validation-provider #default="validationContext" name="Problem Details" rules="required">
                  <b-form-group label-for="problem_details">
                    <template #label>
                      <span>Details<span style="color: tomato">*</span></span>
                    </template>

                    <b-form-textarea
                      id="problem_details"
                      v-model="new_data.problem_details"
                      rows="5"
                      placeholder="Enter the problem details"
                      :disabled="disableForm"
                      :state="getValidationState(validationContext)"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }} 
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="12">
                <validation-provider #default="validationContext" name="Date" rules="required">
                  <b-form-group label-for="problem">
                    <template #label>
                      <span>Date<span style="color: tomato">*</span></span>
                    </template>

                    <b-form-datepicker
                      v-model="new_data.problem_date"
                      :close-button="true"
                      reset-button
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      placeholder="Date"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="12" class="mt-1">
                <validation-provider #default="validationContext" name="Attachments" rules="">
                  <b-form-group label-for="title">
                    <template #label>
                      <span>Attachment{{ attachmentPaths.length > 1 ? `s` : `` }}</span>
                    </template>

                    <b-row>
                      <b-col 
                        v-for="(attachment, index) in attachmentPaths"
                        :key="index"
                        class="mt-1"
                      >
                        <b-col
                          v-if="attachment.path"
                          class="mb-1 image-container"
                        >
                          <div>
                            <b-media class="text-center">
                              <b-img
                                :src="attachment.path"
                                style="width: 150px; height: auto; border-radius: 10px;"
                                class=""
                                match-height
                              />
                            </b-media>
                          </div>
                        </b-col>
                      </b-col>
                    </b-row>

                    <div>
                      <b-link @click="$refs.upload.$el.childNodes[0].click()">
                        <feather-icon icon="UploadCloudIcon" />
                        <span class="align-middle ml-50">{{ attachmentPaths.length === 0 ? `Upload` : `Change` }} Attachment{{ attachmentPaths.length > 1 ? `s` : `` }}</span>
                      </b-link>
                      
                      <div>
                        <b-form-file v-show="false" ref="upload" accept="image/*" multiple no-drop @input="onImageFileRenderer" />
                      </div>
                    </div>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            
            <!-- Form Actions -->
            <div class="d-flex mt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="mr-2"
                type="submit"
                :disabled="invalid || disableForm"
              >
                Save & Exit
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-danger"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, 
  BFormTextarea, BFormInvalidFeedback, BButton, BFormSelect,
  BAlert, BCardText, BModal, BRow, BCol, BFormDatepicker,
  BLink, BFormFile, BImg, BMedia,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { required } from '@validations';
import { get, cloneDeep } from 'lodash';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

const rawData = {
  problem_title: '',
  problem_details: '',
  problem_date: new Date()
}

export default {
  name: "AddOrUpdateCarBrandModal",
  components: {
    BRow,
    BCol,
    BImg,
    BForm,
    BModal,
    BLink,
    BMedia,
    BAlert,
    BButton,
    vSelect,
    BOverlay,
    BSidebar,
    BCardText,
    BFormGroup,
    BFormFile,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BFormDatepicker,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddOrUpdateActive',
    event: 'update:is-add-or-update-active',
  },
  props: {
    isAddOrUpdateActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      loading: false,
      errorMessage: "",

      updatedFiles: [],
      attachmentPaths: [],
      new_data: cloneDeep(rawData),
      statusOptions: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
      ],
    }
  },
  computed: {
    disableForm(){
      return false
    },
  },
  methods: {
    async onCreateOrUpdate(){
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.update_modal_form.validate();
        if (!success){
          return;
        }

        const formData = new FormData();

        formData.append("problem_date", this.new_data.problem_date)
        formData.append("problem_title", this.new_data.problem_title)
        formData.append("problem_details", this.new_data.problem_details)

        if (this.updatedFiles.length) {
          this.updatedFiles.forEach(file => {
            formData.append("attachments", file);
          });
        }
        
        await this.useJwt().ristic.reportedProblems.add(formData)    
        this.attachmentPaths = []
        this.updatedFiles = []          
        this.$emit('update:is-add-or-update-active', false);
        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            variant: 'success',
            icon: 'CheckCircleIcon',
            text: `Problem reported successfully, support will contact you shortly`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onImageFileRenderer(files) {
      this.attachmentPaths = []
      this.updatedFiles = []
          
      files.forEach(async (file) => {
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            this.attachmentPaths.push({ path: reader.result });
            this.updatedFiles.push(file);
          },
          false,
        );
        
        reader.readAsDataURL(file);
      });
    },
    removeImage(index){
      this.attachmentPaths.splice(index, 1);
      this.updatedFiles.splice(index, 1);
    },
    resetForm() {
      this.new_data = cloneDeep(rawData)
      this.attachmentPaths = []
      this.updatedFiles = []
      if (this.$refs.update_modal_form) {
        this.$refs.update_modal_form.reset()
      }
      this.$emit('update:editing-data', null);
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
