<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <h1 class="font-weight-bolder text-dark">
        {{ title }}
      </h1>

      <div class="d-flex align-items-center">
        <b-button 
          class="mr-1 rounded-circle p-0" 
          size="sm" 
          variant="primary"
          style="width: 40px; height: 40px;" 
          :disabled="isScrollAtStart"
          @click="prev"
        >
          <feather-icon icon="ChevronLeftIcon" size="20" />
        </b-button>

        <b-button 
          class="rounded-circle p-0" 
          size="sm" 
          variant="primary"
          :disabled="isScrollAtEnd"
          style="width: 40px; height: 40px;" 
          @click="next"
        >
          <feather-icon icon="ChevronRightIcon" />
        </b-button>
      </div>
    </div>

    <swiper
      ref="swiper"
      class="swiper-multiple"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      @change="onSlideChange"
      @slideChange="onSlideChange"
    >
      <slot></slot>
    </swiper>
  </div>
</template>

<script>
  import { BButton, BRow } from 'bootstrap-vue';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  
  import 'swiper/css/swiper.css'

  export default {
    components: {
      BRow,
      BButton,

      Swiper,
      SwiperSlide,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isScrollAtStart: true,
        isScrollAtEnd: false,

        swiperData: [
          { icon: 'GithubIcon', text: 'Getting Started' },
          { icon: 'FacebookIcon', text: 'Pricing & Plans' },
          { icon: 'TwitterIcon', text: 'Sales Question' },
          { icon: 'InstagramIcon', text: 'Usage Guides' },
          { icon: 'GitlabIcon', text: 'General Guide' },
        ],
        swiperOptions: {
          spaceBetween: 10,
          slidesPerView: 6,
          centeredSlides: false,
          autoplay: {
            delay: 2500,
            disableOnInteraction: false,
          },
          breakpoints: {
            1024: {
              slidesPerView: 6,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              // spaceBetween: 20,
            },
            320: {
              slidesPerView: 1,
              centeredSlides: true,
              // spaceBetween: 10,
            },
          },
        },
      }
    },
    methods: {
      prev() {
        this.$refs.swiper.$swiper.slidePrev()
      },
      next() {
        this.$refs.swiper.$swiper.slideNext()
      },
      onSlideChange() {
        const { progress } = this.$refs.swiper.$swiper;
        this.isScrollAtStart = false;
        this.isScrollAtEnd = false;

        if (progress < 0.1){
          this.isScrollAtStart = true;
        } else if (progress > 0.9){
          this.isScrollAtEnd = true;
        }
      }
    },
  }
</script>
