<template>
  <b-overlay rounded="sm" :show="loading">
    <div class="text-center">
        <div class="mt-3 mb-1">
          <h1>Welcome to Ristic Cars.</h1>
        </div>

        <div>
          <p>
            An email has been sent to {{ registeredEmail }} for account confirmation.
          </p>
        </div>

        <div v-if="!resent_account_confirmation_email">
          <p>
            Email not received?
            <b-button variant="link" size="sm" class="link p-0" @click="resendAccountConfirmationEmail">
              Resend now
            </b-button>
          </p>
        </div>

        <div>
          <b-button variant="primary" size="md" class="btn btn-primary" @click="toggleUIState('login')">
            Login now
          </b-button>
        </div>
    </div>
  </b-overlay>
</template>

<script>
import AppLogo from "@core/layouts/components/Logo.vue";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";

import { get } from "lodash";
import { required } from "@core/utils/validations/validations";
import {
  BAlert,
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BImg,
  BLink,
  BOverlay,
  BRow,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "SignupSuccess",
  components: {
    AuthWrapper,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // BAlert,
    AppLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      authentication_method: "",
      loading: false,
      resent_account_confirmation_email: false,
      // validation rules
      required,
    };
  },
  computed: {
    registeredEmail() {
      return sessionStorage.getItem("registration_email") || "you";
    },
  },
  beforeRouteLeave() {
    sessionStorage.removeItem("registration_email");
  },
  methods: {
    async resendAccountConfirmationEmail() {
      try {
        this.loading = true;
        await this.useJwt().authService.resendAccountConfirmationEmail({
          username: this.registeredEmail,
        });
        this.resent_account_confirmation_email = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Resent Email Confirmation Link",
            icon: "AlertTriangleIcon",
            variant: "success",
            text: "Email confirmation link sent successfully.",
          },
        });
        this.$store.commit("ristic/RESET_AUTH_UI_STATE");
      } catch (error) {
        this.blogPost = undefined;
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    goToHome() {
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    },
    toggleUIState(key = 'register') {
      this.$emit('close')
      return this.$store.commit("ristic/SET_AUTH_UI_STATE", { key, value: true });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
