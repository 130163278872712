<template>
  <div class="booking-car-card">
    <b-card-img :src="car.image" alt="Image" class="car-image"></b-card-img>
    <div class="car-details">
      <h4
        v-b-tooltip.hover
        class="ellipsis text-18"
        style="max-width: 180px"
        variant="text"
        :title="car.car_name"
      >
        {{ car.car_name }}
      </h4>
      <h6 style="color: #a1cb3a" class="text-18">
        ${{ formatMoney(car.rate) }}/<span class="text-muted">day</span>
      </h6>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BButton,
  BCardImg,
  BCardBody,
  VBTooltip,
} from "bootstrap-vue";

export default {
  name: "BookingCarDisplayCard",
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BButton,
    BCardText,
    BCardImg,
    BCardBody,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    car: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    md: {
      type: Number,
      default: 2,
    },
    containerClass: {
      type: String,
      default: "pl-0",
    },
  },
};
</script>

<style lang="scss" scoped>
.booking-car-card {
  display: flex;
  flex-direction: row;
  background-color: white;
  height: 94px;
  width: 330px;
  border-radius: 10px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 6px 30px 0px #0e364b14;
}

.car-image {
  width: 137px;
  height: 94px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  object-fit: cover;
}

.car-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;
  padding-left: 10px;
  gap: 5px;
  width: 100%;
}
</style>
