<template>
  <div>
    <b-row class="pt-5 pb-2 px-4">
      <b-col cols="12" md="5" class="mr-5">
        <b-img :src="require('@/assets/images/logo/logo.png')" class="mb-2" style="height: 50px;" />
        <validation-observer ref="contactForm" #default="{invalid}">
          <b-form @submit.prevent="validateUser">
            <b-form-group
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Your email address"
                vid="email"
                rules="email"
              >
                <b-form-input
                  v-model="formData.email"
                  placeholder="Your email address"
                  class="border-white"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="message"
            >
              <validation-provider
                #default="{ errors }"
                name="Message"
                vid="message"
                rules="min:100"
              >
                <b-form-textarea
                  v-model="formData.message"
                  class="border-white"
                  placeholder="Message"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              block
              class="py-1"
              type="submit"
              variant="primary"
              :disabled="invalid || !disableBtn"
            >
             Send Mail
            </b-button>

            <small 
              class="text-danger mt-2"
            >
              {{ error_message }}
            </small>
          </b-form>
        </validation-observer>
      </b-col>

      <b-col cols="12" md="1" class="mt-1">
        <h4 class="font-weight-bold text-dark mb-1">
          Menu
        </h4>

        <ul class="footer-menu">
          <li
            v-for="(item, i) in menuItems"
            :key="i"
            class="nav-item"
          >
            <b-link
              class="d-flex align-items-center"
              :to="{ name: item.route }"
            >
              <span class="menu-title text-truncate">{{ item.title }}</span>
            </b-link>
          </li>
        </ul>
      </b-col>

      <b-col cols="12" md="2" class="mt-1">
        <h4 class="font-weight-bold text-dark mb-1">
          Find out
        </h4>

        <ul class="footer-menu">
          <li
            v-for="(item, i) in otherMenu"
            :key="i"
            class="nav-item"
          >
            <b-link
              class="d-flex align-items-center"
              :to="{ name: item.route }"
            >
              <span class="menu-title text-truncate">{{ item.title }}</span>
            </b-link>
          </li>
        </ul>
      </b-col>

      <b-col cols="12" md="3" class="mt-1">
        <h4 class="font-weight-bold text-dark mb-1">
          Follows us on
        </h4>

        <div class="d-flex align-items-center mb-3">
          <b-button
            variant="outline-light"
            class="btn-icon rounded-circle bg-white mr-2"
          >
            <feather-icon icon="InstagramIcon" size="18" class="text-primary" />
          </b-button>

          <b-button
            variant="outline-light"
            class="btn-icon rounded-circle bg-white mr-2"
          >
            <feather-icon icon="YoutubeIcon" size="18" class="text-primary" />
          </b-button>

          <b-button
            variant="outline-light"
            class="btn-icon rounded-circle bg-white"
          >
            <feather-icon icon="FacebookIcon" size="18" class="text-primary" />
          </b-button>
        </div>

        <h4 class="font-weight-bold text-dark mb-1">
          Contact us on
        </h4>

        <b-card-text class="">
          info@risticcars.com
        </b-card-text>

        <b-card-text class="">
          0546891427
        </b-card-text>
      </b-col>
    </b-row>

    <hr />

    <b-card-text class="text-center">
      Copyright © 2024 Ristic Cars Limited - All rights reserved
    </b-card-text>

    <validate-contact-email-modal
      :is-add-or-update-active.sync="isAddOrUpdateActive"
      @sendEmail="onContactUsSubmit"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BLink,
  BForm,
  BButton,
  BCardText,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue';

import { get } from 'lodash';
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import PublicMenuItems from "@/navigation/public";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ValidateContactEmailModal from '@/@core/components/shared/ValidateContactEmailModal.vue';

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BForm,
    BLink,
    BButton,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidateContactEmailModal,

    ValidationProvider,
    ValidationObserver, 
  },
  data() {
    return {
      loading: false,
      isAddOrUpdateActive: false,
      error_message: '',
      formData: {
        email: '',
        message: ''
      },
      menuItems: PublicMenuItems,
      otherMenu: [
        {
          title: 'Terms & Conditions',
          route: "terms",
        },

        {
          title: 'Privacy Policy',
          route: "privacy",
        },
      ],


      required,
    }
  },
  computed: {
    disableBtn(){
      return this.formData.email && this.formData.message
    },
  },
  methods: {
    async onContactUsSubmit() {
      try {
        this.loading = true

        const success = await this.$refs.contactForm.validate();
        if (!success) {
          throw new Error("Please check and make sure all fields are valid");
        }

        const formData = new FormData();
        formData.append("email", this.formData.email);
        formData.append("message", this.formData.message);

        await this.useJwt().ristic.contactEmails.create(formData);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Email sent Successfully'
          },
        });

        this.formData.email = '',
        this.formData.message = ''
      } catch (error) {
        console.log(error);
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;
      } finally {
        this.loading = false;
      }
    },
    validateUser() {
      this.isAddOrUpdateActive = true
    },
  }
}
</script>
