<template>
  <div class="">

  <b-overlay :show="loading">
    <div class="text-center d-flex flex-column" style="gap: 30px;">
      <div>
        <app-logo width="148px" />
      </div>
      <h1 class="text-24">Forgot Password ?</h1>
      <div class="my-2">
        <p>
          Please enter your email/phone number and we’ll send you
          instructions to reset your password.
        </p>
      </div>
    </div>
    <b-alert
        variant="danger"
        show
    >
      <div v-if="error" class="alert-body font-small-2">
        <b-card-text>
          <feather-icon icon="AlertCircleIcon" />
          <small>{{ error }}</small>
        </b-card-text>
      </div>
    </b-alert>

    <validation-observer ref="passwordResetRequestForm" #default="{invalid}">
      <b-form class="d-flex flex-column" style="gap: 10px;" @submit.prevent="requestPasswordReset">
        <b-form-group
          label-for="login-username"
        >
          <validation-provider
            #default="{ errors }"
            name="Email / Phone"
            vid="username"
            rules="required"
          >
            <b-form-input v-model="username" size="lg" placeholder="Username / Email"></b-form-input>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div>
          <b-button
            class="py-1"
            type="submit"
            variant="primary"
            block
            :disabled="invalid"
          >
            Send Password Reset Link
          </b-button>
        </div>

        <div class="text-center">
          <p class="pt-2">
            <!-- Remember your credentials? -->
            <span>
              <b-link style="color: #1E6BFF; text-align: center; margin-top: 1.7rem;" @click="loginHandler">
                <span>Back to Login</span>
              </b-link>
            </span>
          </p>
        </div>
      </b-form>
    </validation-observer>
  </b-overlay>

  <!-- <h4 class="text-center my-2 text-18-600" style="color: #151314">or</h4>

  <social-auth /> -->
</div>
</template>

<script>

import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import SocialAuth from "@/@core/components/shared/SocialAuth.vue"

import { get } from "lodash"
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import AppLogo from '@core/layouts/components/Logo.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {
  BImg,
  BForm,
  BLink,
  BAlert,
  BButton,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  VBTooltip,
} from 'bootstrap-vue'
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    SocialAuth,
    AuthWrapper,
    BAlert,
    BLink,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BImg,
    BForm,
    BButton,
    // BAlert,
    AppLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      error: '',
      remember_me: '',
      password: '',
      username: '',
      // validation rules
      required
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    const { token } = this.$route.query;
    if (token) {
      this.confirmAccount(token)
    }
  },
  methods: {
    async requestPasswordReset() {
      try {
        this.loading = true;
        const success = await this.$refs.passwordResetRequestForm.validate();
        if (!success){
          return;
        }

        const response = await this.useJwt().authService.requestPasswordReset({ username: this.username });

        const { mfa_reset_token = null, message, mfa_method } = response.data || {}        
        if (mfa_reset_token) {
          this.useJwt().mfaService.setMFAPasswordResetToken(mfa_reset_token)
          this.$router.replace({ name: "auth-forgot-password-mfa", params: { mfa_method } })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });
          })
          .catch(() => {});
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Password reset link has been sent successfully. Please check your inbox.',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          }); 
          this.$emit('closeMobile')
        }
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.errorMessage = error_message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.$store.commit("ristic/RESET_AUTH_UI_STATE");
        this.loading = false;
      }
    },
    loginHandler() {
      return this.$store.commit("ristic/SET_AUTH_UI_STATE", { key: 'login', value: true });
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
@import '@core/scss/vue/pages/page-public.scss';

sup {
  font-size: 1rem;
}

.text-24 {
  color: #151314;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
