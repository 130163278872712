<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
      @toggle-horizontal-mobile-menu-active="$emit('toggle-horizontal-mobile-menu-active')"
    />
  </ul>
</template>

<script>
import { provide, ref } from '@vue/composition-api'
import { resolveHorizontalMobileNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'

import VerticalNavMenuHeader from '../horizontal-mobile-nav-menu-header'
import VerticalNavMenuGroup from '../horizontal-mobile-nav-menu-group/HorizontalMobileNavMenuGroup.vue'
import HorizontalMobileNavMenuLink from '../horizontal-mobile-nav-menu-link/HorizontalMobileNavMenuLink.vue'

export default {
  components: {
    VerticalNavMenuGroup,
    VerticalNavMenuHeader,
    HorizontalMobileNavMenuLink,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>
