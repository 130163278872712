<template>
  <b-modal
    id="add-or-update-modal"
    :visible="isAddOrUpdateActive"
    centered
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    size="md"
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:is-add-or-update-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            Identity Validation
          </h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>

        <validation-observer
          #default="{ handleSubmit, invalid }"
          ref="update_modal_form"
        >
          <b-form class="my-1" @submit.prevent="handleSubmit(onCheckResult)" @reset.prevent="resetForm">
            <b-alert v-if="errorMessage" variant="danger" :show="true">
              <div class="alert-body font-small-2">
                <b-card-text class="mb-0">
                  {{ errorMessage }}
                </b-card-text>
              </div>
            </b-alert>

            <b-row class="mt-2">
              <b-col>
                <validation-provider #default="validationContext" name="Verification" rules="required">
                  <b-form-group label-for="verification">
                    <template #label>
                      <span>Kindly answer to verify your identity ({{ number_one }} + {{ number_two }})</span>
                    </template>

                    <b-form-input
                      id="verification"
                      v-model="user_answer"
                      type="number"
                      :placeholder="`${ number_one } + ${ number_two }`"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="invalid"
              >
                Submit Answer
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-danger"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, 
  BFormTextarea, BFormInvalidFeedback, BButton, BFormSelect,
  BAlert, BCardText, BModal, BRow, BCol, BFormDatepicker
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  name: "ValidateContactEmailModal",
  components: {
    BRow,
    BCol,
    BForm,
    BModal,
    BAlert,
    BButton,
    vSelect,
    BOverlay,
    BSidebar,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BFormDatepicker,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddOrUpdateActive',
    event: 'update:is-add-or-update-active',
  },
  props: {
    isAddOrUpdateActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      loading: false,
      errorMessage: "",

      number_one: this.getRandomNumber(),
      number_two: this.getRandomNumber(),
      user_answer: 0,
      isCorrect: false,

      statusOptions: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
      ],
    }
  },
  methods: {
    onCheckResult(){
      const success = this.$refs.update_modal_form.validate();
      if (!success){
        return;
      }

      const correct_answer = this.number_one + this.number_two

      if (correct_answer === parseInt(this.user_answer)){
        this.$emit('sendEmail');
        this.$emit('update:is-add-or-update-active', false);
      } else {
        this.number_one = this.getRandomNumber()
        this.number_two = this.getRandomNumber()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Your answer was incorrect, kindly try again.'
          },
        });
      }
    },
    getRandomNumber() {
      return Math.floor(Math.random() * 30) + 1; 
    },
    resetForm() {
      this.user_answer = 0,
      this.isCorrect = false,
      this.number_one = this.getRandomNumber()
      this.number_two = this.getRandomNumber()
      if (this.$refs.update_modal_form) {
        this.$refs.update_modal_form.reset()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
