<template>
  <div class="">
  
    <b-overlay :show="loading">
      <div class="text-center d-flex flex-column" style="gap: 30px;">
        <div>
          <app-logo width="148px" />
        </div>
        <h1 class="text-24 mb-2">Login</h1>
      </div>
      <b-alert
          variant="danger"
          show
      >
        <div v-if="error" class="alert-body font-small-2">
          <b-card-text>
            <feather-icon icon="AlertCircleIcon" />
            <small>{{ error }}</small>
          </b-card-text>
        </div>
      </b-alert>
  
      <validation-observer ref="loginForm" #default="{invalid}">
        <b-form class="d-flex flex-column" style="gap: 10px;" @submit.prevent="login">
          <b-form-group
            label-for="login-username"
          >
            <validation-provider
              #default="{ errors }"
              name="Email / Phone"
              vid="username"
              rules="required"
            >
              <b-form-input v-model="username" size="lg" placeholder="Username / Email"></b-form-input>
  
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
  
          <b-form-group label-for="login-password">
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="password"
              rules="required"
            >
              <b-input-group size="lg">
                <b-form-input v-model="password" style="border-right: none" autocomplete="false" size="lg" :type="passwordFieldType" placeholder="Password"></b-form-input>
                <b-input-group-append is-text>
                  <feather-icon color="black" size="15" :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility"></feather-icon>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
  
          <div>
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="invalid"
            >
              Sign in
            </b-button>
          </div>
        </b-form>
      </validation-observer>
  
      <p class="mb-2 cursor-pointer" style="color: #1E6BFF; text-align: center; margin-top: 1.7rem;" @click="forgotPasswordHandler">Forgotten Password?</p>
    </b-overlay>
  
    <h4 class="text-center my-2 text-18-600" style="color: #151314">or</h4>
  
    <social-auth :show-sign-up-link="true" />
  </div>
</template>

<script>

import { MUTATE_LOGIN_STATUS, MUTATE_USER_DATA } from "@/store/config/mutation-keys"
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import SocialAuth from "@/@core/components/shared/SocialAuth.vue"

import { get } from "lodash"
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import AppLogo from '@core/layouts/components/Logo.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {
  BImg,
  BForm,
  BLink,
  BAlert,
  BButton,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  VBTooltip,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    SocialAuth,
    AuthWrapper,
    BAlert,
    BLink,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BImg,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    AppLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      error: '',
      remember_me: '',
      password: '',
      username: '',
      // validation rules
      required
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isPartner() {
      const env = process.env.VUE_APP_PLATFORM === "partner";
      return env;
    },
    isOMC() {
      const env = process.env.VUE_APP_PLATFORM === "omc";
      return env;
    },
    homeRoute() {
      if (this.isPartner) {
        return "partner-home";
      } 
      if (this.isOMC) {
        return "omc-home";
      }
      return "client-home";
    },
    authRentalUIState() {
      return this.$store.state.ristic.authRentalUIState
    },
  },
  created() {
    const { token } = this.$route.query;
    if (token) {
      this.confirmAccount(token)
    }
  },
  methods: {
    async confirmAccount(confirmAccountToken) {
      try {
        this.loading = true;
        if (confirmAccountToken) {
          await this.useJwt().authService.setAccountConfirmationToken(confirmAccountToken);

          const accountConfirmationStatus = await this.useJwt().authService.confirmAccount();
          const { activated, token } = get(accountConfirmationStatus, "data.data", {});

          if (activated) {
            this.useJwt().authService.setToken(token)

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Your account has been activated successfully.',
              },
            })

            await this.useJwt().authService.clearAccountConfirmationToken();
            this.$router.push({ name: "auth-register-success" });
          } else {
            this.error = "Your account confirmation token has expired. Please login to get a new confirmation token.";
          }
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        })

        this.error = error_message;
      } finally {
        this.loading = true;
      }
    },
    async login() {
      try {
        this.loading = true

        const success = await this.$refs.loginForm.validate();
        if (!success) {
          throw new Error("Please check and make sure all fields are valid");
        }
        const response = await this.useJwt()
            .authService
            .login({
              username: this.username,
              password: this.password,
            });

        const response_data = response.data.data;
        const { mfa_auth_required } = response_data;

        // check if user has mfa enabled
        if (mfa_auth_required) {
          // save access token from payload into app jwt auth service
          // retrieve saved user preferred mfa method to proceed on mfa type
          const { mfa_access_token, mfa_method } = response_data;
          this.useJwt().mfaService.setMfaToken(mfa_access_token);

          // check if mfa method is app
          // proceed to authentication app QR code screen if mfa method is app
          // proceed to verify sent mfa otp if mfa method is email/sms
          this.$router.push({
            name: "security-mfa-authentication",
            params: { mfa_method }
          })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Set-up multi factor authentication',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Kindly secure your account'
                  },
                });
              })
              .catch(() => {
              });
        } else {
          const {
            access_token,
            user,
          } = response_data; 
          this.useJwt()
              .authService
              .setToken(access_token)
          this.$store.commit(`auth/${MUTATE_LOGIN_STATUS}`, true)
          this.$store.commit(`auth/${MUTATE_USER_DATA}`, user);

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Welcome ${user.first_name || user.last_name}`,
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: `You have successfully logged in.`,
            },
          })
          this.$refs.loginForm.reset()
          this.$store.commit("ristic/RESET_AUTH_UI_STATE");

          if (this.authRentalUIState) {
            this.$emit('continueBrowse')
          }
          this.$emit('closeMobile')
        }
      } catch (error) {
        console.log(error);
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message
      } finally {
        this.loading = false;
      }
    },
    forgotPasswordHandler() {
      return this.$store.commit("ristic/SET_AUTH_UI_STATE", { key: 'forgotPassword', value: true });
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
@import '@core/scss/vue/pages/page-public.scss';

sup {
  font-size: 1rem;
}

.text-24 {
  color: #151314;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
