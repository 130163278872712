import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

export default function useLayoutHorizontal(navbarMenuType, footerType) {
  const currentBreakpoint = ref('xl')
  // const currentBreakpoint = computed({
  //   get: () => store.getters['app/currentBreakPoint'],
  //   set: val => {
  //     store.commit('verticalMenu/UPDATE_HORIZONTAL_MOBILE_MENU_COLLAPSED', val)
  //   },
  // })
  const isHorizontalMobileNavMenuActive = ref(true);

  const toggleHorizontalMobileMenuActive = () => {
    isHorizontalMobileNavMenuActive.value = !isHorizontalMobileNavMenuActive.value
  }

  const isHorizontalMobileMenuCollapsed = computed(() => store.state.horizontalMenu.isHorizontalMobileMenuCollapsed);

  const layoutClasses = computed(() => {
    const classes = []

    if (currentBreakpoint.value === 'xl') {
      classes.push('horizontal-menu')
    } else {
      classes.push('horizontal-mobile-menu')
      // classes.push('vertical-layout')
      classes.push(isHorizontalMobileNavMenuActive.value ? 'menu-open' : 'menu-hide')
    }

    // Navbar
    classes.push(`navbar-${navbarMenuType.value}`)

    // Footer
    //! For this app, we default to static footer
    classes.push('footer-static')
    // if (footerType.value === 'sticky') classes.push('footer-fixed')
    // if (footerType.value === 'static') classes.push('footer-static')
    // if (footerType.value === 'hidden') classes.push('footer-hidden')

    return classes
  })

  // const resizeHandler = () => {
  //   isVerticalMenuActive.value = window.innerWidth >= 1200

  //   if (window.innerWidth >= 1200) currentBreakpoint.value = 'xl'
  //   else if (window.innerWidth >= 992) currentBreakpoint.value = 'lg'
  //   else if (window.innerWidth >= 768) currentBreakpoint.value = 'md'
  //   else if (window.innerWidth >= 576) currentBreakpoint.value = 'sm'
  //   else currentBreakpoint.value = 'xs'
  // }

  // ------------------------------------------------
  // Resize handler for Breakpoint
  // ------------------------------------------------
  watch(currentBreakpoint, val => {
    isHorizontalMobileNavMenuActive.value = val === 'xl'
  })

  const resizeHandler = () => {
    // ? This closes vertical menu when title bar is shown/hidden in mobile browsers.
    // ? We will watch for breakpoint to overcome this issue
    // isHorizontalMobileNavMenuActive.value = window.innerWidth >= 1200

    // ! You can use store getter to get breakpoint
    if (window.innerWidth >= 1200) currentBreakpoint.value = 'xl'
    else if (window.innerWidth >= 992) currentBreakpoint.value = 'lg'
    else if (window.innerWidth >= 768) currentBreakpoint.value = 'md'
    else if (window.innerWidth >= 576) currentBreakpoint.value = 'sm'
    else currentBreakpoint.value = 'xs'
  }

  const overlayClasses = computed(() => {
    if (currentBreakpoint.value !== 'xl' && isHorizontalMobileNavMenuActive.value) return 'show'
    return null
  })

  const navbarMenuTypeClass = computed(() => {
    if (navbarMenuType.value === 'sticky') return 'fixed-top'
    if (navbarMenuType.value === 'static') return ''
    if (navbarMenuType.value === 'hidden') return 'd-none'
    return 'floating-nav'
  })

  const footerTypeClass = computed(() => {
    if (footerType.value === 'static') return 'footer-static'
    if (footerType.value === 'hidden') return 'd-none'
    return ''
  })

  return {
    layoutClasses,
    resizeHandler,
    overlayClasses,
    footerTypeClass,
    navbarMenuTypeClass,
    isHorizontalMobileMenuCollapsed,
    toggleHorizontalMobileMenuActive,
  }
}
