import { ref, computed } from '@vue/composition-api';

import store from '@/store'

export default function useHorizontalMobileNavMenu(props) {
  // ------------------------------------------------
  // isHorizontalMobileMenuCollapsed
  // ------------------------------------------------
  const isHorizontalMobileMenuCollapsed = computed({
    get: () => store.state.verticalMenu.isHorizontalMobileMenuCollapsed,
    set: val => {
      store.commit('verticalMenu/UPDATE_HORIZONTAL_MOBILE_MENU_COLLAPSED', val)
    },
  })

  // ------------------------------------------------
  // collapseTogglerIcon
  // ------------------------------------------------
  const collapseTogglerIcon = computed(() => {
    if (props.isVerticalMenuActive) {
      return isHorizontalMobileMenuCollapsed.value ? 'unpinned' : 'pinned'
    }
    return 'close'
  })

  const isMouseHovered = ref(false)

  const updateMouseHovered = val => {
    isMouseHovered.value = val
  }

  const toggleCollapsed = () => {
    isHorizontalMobileMenuCollapsed.value = !isHorizontalMobileMenuCollapsed.value
  }

  return {
    isMouseHovered,
    toggleCollapsed,
    updateMouseHovered,
    collapseTogglerIcon,
    isHorizontalMobileMenuCollapsed,
  }
}
